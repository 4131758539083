import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 取得技巧訊息列表
export function getTrickList (params) {
  return request({
    url: '/api/console/lottery/winskill',
    method: 'get',
    params: params
  })
}

// 取得單一技巧訊息
export function getTrickDetail (id) {
  return request({
    url: `/api/console/lottery/winskill/${id}`,
    method: 'get'
  })
}

// 技巧訊息狀態變更
export function changeTrickState (id, status) {
  return request({
    url: `/api/console/lottery/winskill/${id}`,
    method: 'get',
    params: {
      is_visible: status
    }
  })
}

// 刪除技巧訊息
export function deleteTrick (id) {
  return request({
    url: `/api/console/lottery/winskill/${id}`,
    method: 'delete'
  })
}

// 刪除技巧訊息圖片
export function deleteTrickPhoto (id) {
  return request({
    url: `/api/console/lottery/winskill/${id}/photo`,
    method: 'delete'
  })
}

// 新增技巧訊息
export function postAddTrick (data) {
  return request({
    url: '/api/console/lottery/winskill',
    method: 'post',
    data: getFormData(data)
  })
}

// 修改單則技巧訊息
export function postEditTrick (id, data) {
  return request({
    url: `/api/console/lottery/winskill/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}
