<template>
  <div id="tinymce-vue"></div>
</template>

<script>
import { postEditorPics } from '@/api/admin/news'
import { postUserEditorPics } from '@/api/client/articles'

export default {
  model: {
    prop: 'content',
    event: 'changeContent'
  },
  data () {
    return {
      isDestroyed: false
    }
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    type: {
      type: String
    },
    id: {
      type: String
    }
  },
  mounted () {
    var vm = this
    var postApi = this.type.indexOf('user') > -1 ? postUserEditorPics : postEditorPics
    tinymce.init({
      selector: '#tinymce-vue',
      language: 'zh_CN',
      plugins: 'preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
      // plugins: 'image imagetools', // 插件
      // toolbar: 'image', // 工具栏
      menubar: 'file edit view insert format tools table help',
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview | insertfile image media template link anchor codesample | ltr rtl',
      toolbar_sticky: false,
      height: 360,
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      toolbar_mode: 'sliding',
      images_upload_handler: function (blobInfo, success, failure) {
        const imageKbSize = blobInfo.blob().size / 1000
        const imageMbSize = (imageKbSize / 1024).toFixed(2)
        if (imageMbSize >= 2) {
          tinymce.activeEditor.undoManager.undo()
          failure('图片档案不得大于2ＭＢ')
          return
        }
        const data = {
          id: vm.id,
          type: vm.type,
          upload: blobInfo.blob()
        }
        postApi(data).then(res => {
          if (res.uploaded === 0) {
            tinymce.activeEditor.undoManager.undo()
            failure(res.message)
          } else {
            success(res.url)
          }
        }).catch(err => {
          failure(err)
        })
      },
      setup: function (editor) {
        editor.on('change keyup', () => {
          if (!vm.isDestroyed) {
            const content = editor.getContent()
            // 更新文本
            vm.$emit('changeContent', content)
          }
        })
      }
    }).then(editors => {
      this.editor = editors[0]
      // 载入初始文本
      this.editor.setContent(this.content)
    })
  },
  beforeDestroy () {
    // 销毁
    this.isDestroyed = true
    this.editor.remove()
    this.editor.destroy()
  }
}
</script>
