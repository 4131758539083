import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 取得新聞列表
export function getNewsList (params) {
  return request({
    url: '/api/console/lottery/news',
    method: 'get',
    params: params
  })
}

// 取得單一新聞
export function getNewsDetail (id) {
  return request({
    url: `/api/console/lottery/news/${id}`,
    method: 'get'
  })
}

// 新聞狀態變更
export function changeNewsState (id, status) {
  return request({
    url: `/api/console/lottery/news/${id}`,
    method: 'get',
    params: {
      is_visible: status
    }
  })
}

// 刪除新聞
export function deleteNews (id) {
  return request({
    url: `/api/console/lottery/news/${id}`,
    method: 'delete'
  })
}

// 刪除新聞圖片
export function deleteNewsPhoto (id) {
  return request({
    url: `/api/console/lottery/news/${id}/photo`,
    method: 'delete'
  })
}

// 新增新聞
export function postAddNews (data) {
  return request({
    url: '/api/console/lottery/news',
    method: 'post',
    data: getFormData(data)
  })
}

// 修改單則新聞
export function postEditNews (id, data) {
  return request({
    url: `/api/console/lottery/news/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}

// Editor上傳圖片
export function postEditorPics (data) {
  return request({
    url: '/api/console/ckeditor/upload',
    method: 'post',
    data: getFormData(data)
  })
}
